import {StreamManager, SignalingServerConnection} from 'siemprevideo-streamlib';
import CallManager from './CallManager.js';

import { LogglyTracker } from 'loggly-jslogger';
import firebase from 'firebase';

require("firebase/firestore");

var firebaseConfig = {
  apiKey: "AIzaSyBl1OG62WWFNiV54m7GGQ8BH4_j-V41cyo",
  authDomain: "siemprevideo-browser.firebaseapp.com",
  databaseURL: "https://siemprevideo-browser.firebaseio.com",
  projectId: "siemprevideo-browser",
  storageBucket: "gs://siemprevideo-browser.appspot.com",
  messagingSenderId: "382383985568",
  appId: "1:382383985568:web:9d2e8158e44bd762"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const timestamp = firebase.firestore.Timestamp;

const logger = new LogglyTracker();
logger.push({ 'logglyKey': 'e1b21963-1cda-4a9d-a078-639c958d49b4'});

const wrtc = {
  mediaDevices: navigator.mediaDevices,
  RTCPeerConnection: RTCPeerConnection,
  RTCIceCandidate: RTCIceCandidate,
  RTCSessionDescription: RTCSessionDescription,
};
const signaling = new SignalingServerConnection(auth);
const streamManager = new StreamManager(wrtc);
window.streamManager = streamManager // for debug
const callManager = new CallManager();

const pixelDragThreshold = 20;
const msHoldThreshold = 1000;

export {db, auth, storage, timestamp, logger, streamManager, callManager, signaling, pixelDragThreshold, msHoldThreshold};
