import React from 'react';
import Logo from './res/Logo.png';
import './LoadingScreen.css'; 

class LoadingScreen extends React.Component {
  render() {
    return (
      <div className="loading-screen">
        <div>
          <img src={Logo} alt="Siempre logo" />
        </div>
      </div>
    );
  }
}

export default LoadingScreen;
